.room-card-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.room-card-container .slider-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8; /* Adjust the opacity value as needed for the black mask effect */
  transition: background-image 0.3s ease-in-out;
}

.room-card {
  position: relative;
  height: 500px; /* Set a fixed height */
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #cbcbcb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: background-color 0.5s ease-out;
}

.room-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}
.room-card-container {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 54%, rgba(0,0,0,1) 100%);
  padding: 200px 0px;
  overflow: hidden;
}

.room-card-container .room-details {
  margin-top: 10px;
}

.room-card-container .room-name {
  font-size: 22px;
  margin-bottom: 5px;
  color: rgb(255, 255, 255);
  padding: 20px 0px
} 

.room-card-container .room-description {
  margin-bottom: 55px;
  padding: 0px 20px;
}

.room-card-container .more-details {
  text-align: center;
  padding-bottom: 20px;
}
.room-card-container .img-container {
  overflow: hidden;
  height: 250px;
}
.room-card-container .more-details-button {
  background-color: #000000;
  cursor: pointer;
  border: 1px solid black;
  position: absolute;
  bottom: 0px;
  width: 142px;
  height: 42px;
  left: 50%;
  transform: translate(-50%, 0);
}

.room-card-container .slick-slider {
  position: relative;
}

.room-card-container .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
button.more-details-button a:visited {
  color: rgb(255, 255, 255);
}
button.more-details-button a {
  color: white;
}
button.more-details-button a {
  color: white !important;
  text-decoration: none;
}

.room-card-container .slick-dots li {
  margin: 0 5px;
}

.room-card-container .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb;
  border: none;
  cursor: pointer;
}

.room-card-container .slick-dots li.slick-active button {
  background-color: #000;
}
.room-card-container .slick-slide {
  transition: transform 0.3s ease-in-out;
  transform-origin: center left;
}

.room-card-container .slick-active {
  transform: scale(1.02, 1.1) translateX(-1%);
  z-index: 2;
  
}

 .room-card-container .slick-list{
  height: 600px;
  padding-top: 30px !important;
}
.room-card-container .slick-slide img {
  height: 300px;
  width:auto;
  transition: transform 0.3s ease-in-out;
}

.room-card-container .slick-active img,.room-card-container .slick-active .room-description,.room-card-container .slick-active .more-details ,.room-card-container .slick-active .room-name{

  transform: scale(1.078, 1.001);
}
/* Hide the content in non-active slides */
.room-card-container .slick-slide .room-description,.room-card-container .slick-slide .more-details,.room-card-container .slick-slide img {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Show the content in the active slide */
.room-card-container .slick-active .room-description,.room-card-container .slick-active .more-details,.room-card-container .slick-active img {
  opacity: 1;
}
.room-card-container .slick-active .room-card{
  background-color: white;
  border: none;
}


/* Show the content in the active slide */
.room-card-container .slick-active .room-name {
  color: rgb(0, 0, 0);
}

.more-details-button a{
width: 100%;
line-height: 16px;
display: block;
}